@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "overlayscrollbars/overlayscrollbars.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.proposer-modal .modal-content {
    background-color: #262b31;
    color: #fff
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    @apply bg-ffblack text-white;
    font-family: 'Source Sans Pro';
}


.mat-mdc-slider .mdc-slider__thumb-knob {
    background-color: #893681 !important;
    border-color: #fff !important;
    border-width: 4px !important;
    width: 15px !important;
    height: 15px !important;
}

.mdc-slider__track {
    background-color: #893681 !important;
    height: 7.5px !important;
    border-radius: 3.75px !important;
}

.mat-mdc-slider {
    --mdc-slider-label-container-color: #893681;
    --mdc-slider-label-label-text-color: white;
    --mat-mdc-slider-value-indicator-opacity: 1;
    min-width: 1px !important;
    margin: 0 !important
}

.mdc-slider .mdc-slider__value-indicator-container {
    bottom: 40px;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-track {
    border-radius: 1px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--hover-bg-color);
}

.os-theme-dark {
  --os-handle-bg: var(--hover-bg-color);
  --os-handle-bg-active: var(--hover-bg-color);
  --os-handle-bg-hover: var(--hover-bg-color);
}
